
import { defineComponent, onMounted, inject, reactive, ref } from "vue";
import { useRoute } from "vue-router";

import TableLite from "vue3-table-lite/ts";
import type { Component } from "vue";

// components
import Badge from "@/components/atoms/Badge.atoms.vue";
import DeleteTestParticipantModal from "@/components/molecules/DeleteTestParticipantModal.molecules.vue";
import BackButton from "@/components/atoms/BackButton.vue";

// services
import projectService from "@/services/project.service";

// composables
import useDateFormatter from "@/composables/dateFormatter";
import useProjectStatusBadge from "@/composables/projectStatusBadge";

// interfaces
import { IDeleteReq } from "@/interfaces/participant";

export default defineComponent({
  components: {
    "table-lite": TableLite as unknown as Component,
    Badge,
    DeleteTestParticipantModal,
    BackButton,
  },
  setup() {
    const setPageTitle = inject("setPageTitle") as CallableFunction;
    onMounted(() => setPageTitle("Detail Peserta"));

    const { formatDateTime } = useDateFormatter();

    const route = useRoute();

    interface ITest {
      name: string;
      result: string | null;
      pdf_file: string | null;
      started_at?: string;
      ended_at?: string;
    }

    interface IProjectParticipantDetail {
      project: {
        started_at: string;
        ended_at: string;
        status: string;
      };
      participant: {
        email: string;
      };
      customer: {
        name: string;
      } | null;
      tests: {
        current_page: number;
        data: ITest[];
        from: number;
        to: number;
        total: number;
      };
    }

    let state = reactive({
      participantDetail: {} as IProjectParticipantDetail,
    });
    const isLoading = ref(false);

    onMounted(async () => {
      isLoading.value = true;
      const projectId = Number(route.params.projectId);
      const participantId = Number(route.params.participantId);

      const { data } = await projectService.getProjectParticipantDetail(
        projectId,
        participantId,
        1,
        10
      );

      isLoading.value = false;
      state.participantDetail = data;
    });

    // Init Your table settings
    const table = reactive({
      isLoading: false,
      columns: [
        {
          label: "Assesment",
          field: "name",
          width: "10%",
          sortable: true,
          isKey: true,
        },
        {
          label: "Mulai Mengerjakan",
          field: "started_at",
          width: "10%",
          sortable: true,
        },
        {
          label: "Selesai Mengerjakan",
          field: "ended_at",
          width: "10%",
          sortable: true,
        },
        {
          label: "Hasil",
          field: "result",
          width: "10%",
          sortable: true,
        },
        {
          label: "Aksi",
          field: "action",
          width: "5%",
          sortable: false,
        },
      ],
      rows: [] as ITest[],
      totalRecordCount: 0,
      sortable: {
        order: "id",
        sort: "asc",
      },
    });

    /**
     * Table search event
     */
    const doSearch = async (
      offset: number,
      limit: number,
      order: string,
      sort: string
    ) => {
      const projectId = Number(route.params.projectId);
      const participantId = Number(route.params.participantId);
      const page = offset / limit + 1;

      table.isLoading = true;
      const { data } = await projectService.getProjectParticipantDetail(
        projectId,
        participantId,
        page,
        limit
      );
      table.rows = data.bundles.data.map((d: ITest) => {
        d.started_at = formatDateTime(data.project.started_at, "DD/MM/YYYY");
        d.ended_at = formatDateTime(data.project.ended_at, "DD/MM/YYYY");

        return d;
      });

      table.totalRecordCount = data.tests.total;
    };

    /**
     * Table search finished event
     */
    const tableLoadingFinish = () => {
      table.isLoading = false;
    };

    // Get data first
    doSearch(0, 10, "id", "asc");

    // Actions
    const downloadResult = async (testId: number) => {
      table.isLoading = true;

      const projectId = Number(route.params.projectId);
      const participantId = Number(route.params.participantId);

      const { data } = await projectService.getProjectTestResult(
        projectId,
        testId,
        participantId
      );

      table.isLoading = false;

      window.open(data, "_blank");
    };

    const showDeleteModal = ref<null | { show: (data: IDeleteReq) => null }>(
      null
    );
    const actionDelete = (testId: number) => {
      const projectId = Number(route.params.projectId);
      const participantId = Number(route.params.participantId);
      if (showDeleteModal.value)
        showDeleteModal.value.show({
          participantId,
          testId,
          projectId,
        });
    };

    const { getBadgeVariant } = useProjectStatusBadge();

    return {
      state,
      isLoading,
      table,
      formatDateTime,
      downloadResult,
      showDeleteModal,
      actionDelete,
      doSearch,
      tableLoadingFinish,
      getBadgeVariant,
    };
  },
});
